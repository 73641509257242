<template>
<div class="flow-container">
  <div class="lux-empty">
    <img :src="img.lux.empty" />
    <div class="empty-text">建设中</div>
  </div>
</div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  props: {
  },
  data(){
    return {
    }
  },
  mounted: function(){
  },
  methods: {
    switchTab: function(type){
      this.current = type;
    },
    menuSelected: function(name){
      if(this.currentName == name) return;
      this.currentName = name;
      this.$router.push(`/index/${this.sys}/workorder/${name}?code=${this.types[this.sys]}`);
    },
  }
}
</script>
<style scoped>
.lux-empty{
  width: 100%;
  height: 100%;
  position: relative;
}
.empty-text{
  /* border: solid 1px red; */
  position: absolute;
  top: 340px;
  left: 50%;
  width: 100px;
  height: 26px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #324252;
  line-height: 26px;
  text-align: center;
  margin-left: -50px;
}
.lux-empty img{
  position: absolute;
  top: 100px;
  left: 50%;
  width: 340px;
  height: 290px;
  margin-left: -170px;
}
.flow-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  /* margin-right: 2px; */
  padding: 3px;
  background-color: white;
}
.flow-menus{
  border: solid 1px rgba(114, 113, 113, 0.247);
  border-radius: 6px;
  padding: 3px;
  width: 200px;
  margin-right: 3px;
  /* padding-right: 3px; */
  flex: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.flow-menu-item{
  height: 40px;
  flex: none;
  border: solid 1px rgb(185, 185, 185);
  border-radius: 6px;
  margin-bottom: 3px;
  cursor: pointer;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
}
.flow-menu-item:hover{
  border: solid 1px rgb(4, 236, 35);
}
.menu-active{
  border: solid 1px rgb(54, 0, 247);
  color: blue;
}
.flow-content{
  border: solid 1px red;
  /* margin: 3px; */
  width: 500px;
  flex: auto;
  border: solid 1px rgba(114, 113, 113, 0.247);
  border-radius: 6px;
  padding: 3px;
}
</style>
