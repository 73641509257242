import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=90b9de10&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
import style0 from "./Index.vue?vue&type=style&index=0&id=90b9de10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90b9de10",
  null
  
)

export default component.exports