<template>
<div class="monitor-main">
  <div class="monitor-tree" v-if="!collect">
    <CommonTree />
  </div>
  <div class="monitor-content" :style="{paddingTop: `${current == 'light' ? 0 : 15}px`, paddingRight: `${current == 'light' ? 0 : 15}px`, paddingBottom: `${current == 'light' ? 0 : 15}px`}">
    <div :class="[!collect ? 'left-spliter' : 'right-spliter']" @click="collectTree">
    </div>
    <div class="content-header" v-if="current != 'gis' && current != 'light'">
      <div :class="['header-item', current == item.code ? 'header-selected' : '', idx == menus.length - 1 ? 'header-last-item' : '']" v-for="(item, idx) in menus" :key="idx" @click="switchMenu(item)">{{item.name}}</div>
    </div>
    <div class="content-body">
      <template v-for="(item, idx) in menus">
        <component v-if="item.code == current && item.com" :key="idx" v-bind:is="item.com" :args="item.args"></component>
      </template>
    </div>
  </div>
</div>
</template>
<script>
import { mapState } from 'vuex'
import CommonTree from '../tree/Index'
import Working from '../Working'
// import AssetConfigIndex from '../config/Index'
// import AssetPowerIndex from '../power/Index'
// import AssetOtherIndex from '../other/Index'
// import AssetRecordIndex from '../record/Index'
// import AssetAnalyseIndex from '../analyse/Index'
export default {
  name: 'Home',
  components: {
    CommonTree,
  },
  props: {
  },
  data(){
    return {
      deploy: false,
      current: 's1',
      collect: false,
      menus: [
        {code: 's1', name: 'GIS地图', com: Working, args: {} },
        {code: 's2', name: '资产详情', com: Working, args: {}},
        {code: 's3', name: '资产管理', com: Working, args: {}},
        {code: 's5', name: '运维管理', com: Working, args: {}},
      ],
    }
  },
  computed: {
    ...mapState('auth', ['customerId', 'agentChangeValue', 'currentMenuName', 'sliderMenus', 'urls']),
  },
  watch: {
    currentMenuName(){
      // console.log('current Menu Name changed', this.currentMenuName);
      let menu = this.currentMenuName;
      if(menu == 'analyse' || menu == 'asset'){
        menu = 'pole';
      }
      this.$store.commit('group/setPoleDeviceTypes', menu)
      this.$store.commit('group/selectNodeById', {nodeId: 0, type: 'root'})
    },
  },
  mounted: function(){
  },
  methods: {
    collectTree: function () {
      this.collect = !this.collect;
    },
    switchMenu: function (menu) {
      this.current = menu.code;
    },
  }
}
</script>
<style scoped>
.left-spliter {
  position: absolute;
  left: 0;
  top: calc(50% - 35px);
  width: 14px;
  height: 70px;
  line-height: 70px;
  color: #ffffff;
  background: #007eff;
  border-radius: 0px 12px 12px 0px;
  cursor: pointer;
}
.left-spliter::after {
  position: absolute;
  content: " ";
  top: 30px;
  left: 5px;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-left: solid 2px #ffffff;
  border-bottom: solid 2px #ffffff;
}
.right-spliter {
  position: absolute;
  left: 0;
  top: calc(50% - 35px);
  width: 14px;
  height: 70px;
  line-height: 70px;
  color: #ffffff;
  background: #007eff;
  border-radius: 0px 12px 12px 0px;
  cursor: pointer;
}
.right-spliter::after {
  position: absolute;
  content: " ";
  top: 30px;
  left: -1px;
  width: 10px;
  height: 10px;
  transform: rotate(225deg);
  border-left: solid 2px #ffffff;
  border-bottom: solid 2px #ffffff;
}
.monitor-main {
  width: 100%;
  height: 100%;
  display: flex;
}
.monitor-tree {
  width: 280px;
  flex: none;
}
.monitor-content {
  position: relative;
  /* border: solid 1px red; */
  background-color: #e9edf4;
  width: 720px;
  flex: auto;
  overflow: hidden;
  padding: 15px;
  display: flex;
  flex-direction: column;
}
.content-header {
  /* border: solid 1px red; */
  height: 46px;
  flex: none;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: stretch;
}
.content-body {
  /* border: solid 1px red; */
  height: 400px;
  flex: auto;
  background: transparent;
  overflow: hidden;
  overflow-y: revert;
  /* padding: 10px; */
}
.header-item {
  line-height: 45px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0a1421;
  background-color: #ffffff;
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
  /* border-right: solid 1px red; */
  margin-right: 1px;
}
.header-last-item {
  border-radius: 0 8px 8px 0;
  /* border-right: solid 1px red; */
}
.header-selected {
  color: #ffffff;
  background-color: #007eff;
}
</style>
