<template>
  <div class="tree-container">
    <MyTreeNode v-for="(node, idx) in data" :path="[]" :level="idx" :key="node.id" :node="node" />
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import MyTreeNode from './MyTreeNode'
  export default {
    name: 'MyTree',
    components:{
      MyTreeNode,
    },
    model: {
      prop: 'value',
      event: 'showChanged'
    },
    props: {
      data: {
        type: Array,
        default(){ return [];}
      },
    },
    data () {
      return {
      }
    },
    methods: {
      removeNode: function(node, parent){
        let list = this.data;
        if(parent != null && parent != undefined){
          list = parent.children;
        }
        if(list == null || list.length == 0)return;
        for(let i = 0; i < list.length; i++){
          let n = list[i];
          if(n.type == node.type && n.data.id == node.data.id){
            list.splice(i, 1);
            return;
          }else{
            this.removeNode(node, n);
          }
        }
      },
    }
  }
</script>
<style scoped>
.tree-container{
  /* border: solid 1px red; */
  width: 100%;
  padding-bottom: 50px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>